export default {
  SET_WALLET(state, wallet) {
    state.wallet = wallet
  },
  SET_ACCOUNT(state, account) {
    state.account = account
  },
  SET_SCATTER(state, scatter) {
    state.scatter = scatter
  },
  SET_YTA(state, yta) {
    state.yta = yta
  },
  SET_ACTIVELIST(state, active) {
    state.activeList = active
  },
  // 设置语言
  SET_LANGUAGE(state, lang) {
    state.lang = lang
  },
  // 设置yta余额
  SET_YTABALANCE(state, balance) {
    state.ytaBalance = balance
  },
  // 冲加载
  toReload(state, res) {
    state.isReload = res //先关闭，
  },
  SET_USER(state,user){
    state.user=user
  }
}
