import axios from 'axios'
import { Toast } from 'vant'
// import _this from '../main.js'
import store from '@/store'
// import { goLogin } from "@/utils/index";
import Cookies from 'js-cookie'
// 创建axios实例
const service = axios.create({
  // baseURL: '/ytlapi/index',
  baseURL: '/yipapi',
  timeout: 50000, // 请求超时时间
})

// request拦截器
service.interceptors.request.use(
  (config) => {
    config.headers['Accept-Language'] = store.state.lang
    config.headers['token'] = Cookies.get('token')

    if (config.showIndicator) {
      Toast.loading({
        message: this.$t('tip.loading'),
        forbidClick: true,
      })
    }
    return config
  },
  (error) => {
    // Do something with request error
    Promise.reject(error)
  }
)

// response 拦截器
service.interceptors.response.use(
  (response) => {
    /**
     * code为非20000是抛错 可结合自己业务进行修改
     */
    Toast.clear()
    // console.log(JSON.stringify(response.data))
    const res = response.data
    // console.log(1111, res)
    if (res.code === 0) {
      return response.data
    } else if (res.code == 3) {
      // goLogin();
    } else if (res.code === 4) {
      Toast.fail({
        message: res.msg,
        duration: 3 * 1000,
      })
      return response.data
    } else if (res.code == 135) {
      Toast.fail({
        message: res.msg,
        duration: 3 * 1000,
      })
      return response.data
    } else if (res.code == 166) {
      Toast.fail({
        message: res.msg,
        duration: 3 * 1000,
      })
      return response.data
    } else {
      Toast.fail({
        message: res.msg,
        duration: 3 * 1000,
      })
      return response.data
    }
  },
  (error) => {
    Toast.fail({
      message: error,
      duration: 3 * 1000,
    })
    return Promise.reject(error)
  }
)

export default service
