import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import('@/views/index'),
    meta: {
      title: 'YottaChain Improvement Proposal',
    },
  },
  {
    // 404
    path: '*',
    component: () => import('@/views/404'),
    meta: {
      title: '404',
    },
  },
  {
    // 404
    path: '/detail',
    component: () => import('@/views/detail'),
    meta: {
      title: 'YottaChain Improvement Proposal',
    },
  },
  {
    // 404
    path: '/votedetail',
    component: () => import('@/views/voteDetail'),
    meta: {
      title: 'YottaChain Improvement Proposal',
    },
  },
  {
    path: '/nojoin',
    component: () => import('@/views/nojoin'),
    meta: {
      title: 'YottaChain Improvement Proposal',
    },
  },
  {
    path: '/pdf',
    component: () => import('@/views/pdf'),
    meta: {
      title: 'YottaChain Improvement Proposal',
    },
  },
  {
    path: '/enroll',
    component: () => import('@/views/enroll'),
    meta: {
      title: 'YottaChain Improvement Proposal',
    },
  },
  {
    path: '/governance',
    component: () => import('@/views/governance'),
    meta: {
      title: 'YottaChain Improvement Proposal',
    },
  },
  {
    path: '/information',
    component: () => import('@/views/information'),
    meta: {
      title: 'YottaChain Improvement Proposal',
    },
  },
  {
    path: '/noVote',
    component: () => import('@/views/noVote'),
    meta: {
      title: 'YottaChain Improvement Proposal',
    },
  },
  {
    path: '/initiate',
    component: () => import('@/views/initiate'),
    meta: {
      title: 'YottaChain Improvement Proposal',
    },
  },
  {
    path: '/governVote',
    component: () => import('@/views/governVote'),
    meta: {
      title: 'YottaChain Improvement Proposal',
    },
  },
  {
    // 404
    path: '/voteDetailSecond',
    component: () => import('@/views/voteDetailSecond'),
    meta: {
      title: 'YottaChain Improvement Proposal',
    },
  },
]

const router = new VueRouter({
  routes,
})

export default router
