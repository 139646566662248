export default {
  scatter: {},
  yta: {},
  account: {},
  wallet: 'app',
  lang: 'zh', // 语言 zh:中文  en:英文
  activeList: 1,
  ytaBalance: '',
  isReload: true,
  user:{}
}
