import { getBalance } from '@/api/swap'
import { getUser } from '@/api/user'
import _this from '../main.js'

export default {
  // 切换语言
  setLanguage({ commit }, lang) {
    commit('SET_LANGUAGE', lang)
  },
  // 获取余额
  getBalance({ commit, state }) {
    return new Promise(async (resolve, reject) => {
      try {
        const ytaBalance = await getBalance(state.account.name, 'YTA')
        console.log(ytaBalance)
        console.log('ytaBalance')
        commit('SET_YTABALANCE', Number(ytaBalance))
        resolve()
      } catch (err) {
        reject(err)
      }
    })
  },
  changeload({ commit }) {
    commit('toReload', false)
    return _this.$nextTick(function() {
      commit('toReload', true)
    })
  },
  async getUser({commit},data){
    let res=await getUser(data)
    commit('SET_USER',res.data)
  }
}
