import { pushTransaction, getTableRows, vue } from '@/utils/pushTransaction'

// // 兑换合约账号
// const swapAccount = 'swap.hyj'
// // 抵押备注 deposit,交易对id
// const memo = 'deposit,2'
// // 交易对id
// const pair_id = '2'
// // BBA账号
// const bbaAccount = 'wuheyanfa123'
// // 兑换备注 swap,滑点,交易对id
// const swapMemo = 'swap,0,2'

// YTA账号
const ytaAccount = 'eosio.token'
// BBA账号
const bbaAccount = 'token.bba'
// 兑换合约账号
const swapAccount = 'swap.bba'
// 交易对id
const pair_id = '1'
// 抵押备注 deposit,交易对id
const depositMemo = `deposit,${pair_id}`
// 兑换备注 swap,滑点,交易对id
// const swapMemo = `swap,0,${pair_id}`
const swapMemo = ' sign up'

// 兑换
export async function swap(account, quantity) {
  const symbol = quantity.split(' ')[1]
  const actions = [
    {
      account: symbol == 'YTA' ? ytaAccount : bbaAccount,
      name: 'transfer',
      authorization: [
        {
          actor: account,
          permission: 'active',
        },
      ],
      data: {
        from: account,
        to: swapAccount,
        quantity,
        memo: swapMemo,
      },
    },
  ]

  return await pushTransaction(actions)
}

// 获取交易对信息
export async function getLiquidity(account) {
  const data = {
    code: swapAccount,
    json: true,
    limit: 1,
    lower_bound: account,
    upper_bound: account,
    scope: pair_id,
    table: 'liquidity',
  }
  return await getTableRows(data)
}

// 获取流动性信息
export async function getPairInfo() {
  const data = {
    code: swapAccount,
    json: true,
    limit: 1,
    lower_bound: pair_id,
    upper_bound: pair_id,
    scope: swapAccount,
    table: 'pairs',
  }
  return await getTableRows(data)
}

// 查询余额
export async function getBalance(account, symbol) {
  if (symbol == 'YTA') {
    const res = await vue.$yta.getCurrencyBalance(ytaAccount, account, 'YTA')
    if (res[0]) {
      return res[0].split(' ')[0]
    } else {
      return 0
    }
  } else {
    const res = await vue.$yta.getCurrencyBalance(bbaAccount, account, 'BBA')
    if (res[0]) {
      return res[0].split(' ')[0]
    } else {
      return 0
    }
  }
}

//查询池里的余额总量
export async function getPoolBalance() {
  const res = await vue.$yta.getCurrencyBalance(ytaAccount, swapAccount, 'YTA')
  console.log(res)
  console.log(111)
  if (res[0]) {
    return res[0].split(' ')[0]
  } else {
    return 0
  }
}
// 注入流动性
export async function deposit(account, ytaValue) {
  const actions = [
    {
      // 转账YTA
      account: ytaAccount,
      name: 'transfer',
      authorization: [
        {
          actor: account,
          permission: 'active',
        },
      ],
      data: {
        from: account,
        to: 'mortgageacco',
        quantity: `${Number(ytaValue).toFixed(4)} YTA`,
        memo: depositMemo,
      },
    },
    // {
    //   // 转账MTA
    //   account: bbaAccount,
    //   name: 'transfer',
    //   authorization: [
    //     {
    //       actor: account,
    //       permission: 'active',
    //     },
    //   ],
    //   data: {
    //     from: account,
    //     to: swapAccount,
    //     quantity: `${Number(bbaValue).toFixed(4)} BBA`,
    //     memo: depositMemo,
    //   },
    // },
  ]
  return await pushTransaction(actions)
}

// 取出流动性
export async function withdraw(account, amount) {
  const actions = [
    {
      // 转账YTA
      account: swapAccount,
      name: 'withdraw',
      authorization: [
        {
          actor: account,
          permission: 'active',
        },
      ],
      data: {
        owner: account,
        pair_id,
        amount,
      },
    },
  ]
  return await pushTransaction(actions)
}

//YTA取出
export async function withdrawYTA(account, amount) {
  //参数是用户名 account
  // amount 字符串 数字(小数后必须是4位，不够补0)+空格+YTA

  // let formatedAmount =formatnumber(amount,4) +" YTA"

  const actions = [
    {
      // 转账YTA
      account: swapAccount,
      name: 'withdraw',
      authorization: [
        {
          actor: account,
          permission: 'active',
        },
      ],
      data: {
        quantity: amount,
      },
    },
  ]
  return await pushTransaction(actions)
}

//获取bba yta交易比例
export async function getExscale() {
  const data = {
    code: swapAccount,
    json: true,
    limit: 1,
    scope: swapAccount,
    table: 'exscale',
  }
  return await getTableRows(data)
}
