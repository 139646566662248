import Vue from 'vue'
import App from '@/App.vue'
import store from '@/store'
import router from '@/router'
import { isAndroid } from '@/utils/index'

// 样式
import 'normalize.css'
import '@/styles/index.less'

// 插件
import '@/plugins/vant'
import '@/plugins/scatter'
import '@/plugins/vue-wechat-title'
import '@/plugins/globalComponents'
import '@/plugins/vconsole'
import { i18n } from '@/plugins/i18n'

import { isPc } from '@/utils/index'

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import 'font-awesome/css/font-awesome.css'
import VueHtml5Editor from 'vue-html5-editor'
import '@/plugins/scatter'
// import VConsole from 'vconsole'
dayjs.extend(utc)
import { Uploader, CountDown, Search, DropdownMenu, DropdownItem } from 'vant'
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css' // import styles
import 'quill/dist/quill.snow.css' // for snow theme
import 'quill/dist/quill.bubble.css' // for bubble theme
Vue.use(VueQuillEditor /* { default global options } */)
Vue.use(Uploader)
Vue.use(CountDown)
Vue.use(Search)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
// 生产环境中启用
// if (process.env.NODE_ENV === 'production') {
//   require('@/plugins/fundebug')
// }

// if (process.env.NODE_ENV === 'development') {
// require('@/plugins/fundebug')
// const vConsole = new VConsole();
// Vue.use(vConsole)
// }
// require('@/plugins/fundebug')
// const vConsole = new VConsole()
// Vue.use(vConsole)

// 富文本引入

Vue.config.productionTip = false

Vue.mixin({
  filters: {
    toThousands(num) {
      num = Number(num)
      var str = num.toString()
      var reg =
        str.indexOf('.') > -1 ? /(\d)(?=(\d{3})+\.)/g : /(\d)(?=(?:\d{3})+$)/g
      return str.replace(reg, '$1,')
    },
    assetToValue(asset) {
      return Number(asset.split(' ')[0])
    },
    /**
     * 格式化时间
     */
    formatTime(time) {
      if (time && typeof time === 'string' && time.includes('+08')) {
        return dayjs(time).format('YYYY-MM-DD HH:mm:ss')
      } else {
        return dayjs
          .utc(time)
          .local()
          .format('YYYY-MM-DD HH:mm:ss')
      }
    },
    byteToGb(value) {
      return (value / (1024 * 1024 * 1024)).toFixed(2)
    },
  },
  data() {
    return {
      isAndroid: isAndroid(),
      
    }
  },
  
  computed: {
    account() {
      try {
        return this.$store.state.account.name
      } catch (err) {
        return '未获取到'
      }
    },
    isApp() {
      // return this.$store.state.wallet !== 'ScatterExtension'
      return !isPc()
    },
    ytaBalance() {
      return this.$store.state.ytaBalance
    },
    user(){
      return this.$store.state.user
    }
  },
  methods: {
    assetToValue(asset) {
      return asset.split(' ')[0] * 10000
    },
  },
})

Vue.use(VueHtml5Editor, {
  // 全局组件名称，使用new VueHtml5Editor(options)时该选项无效
  name: 'vue-html5-editor',
  // 是否显示模块名称，开启的话会在工具栏的图标后台直接显示名称
  showModuleName: true,
  // 自定义各个图标的class，默认使用的是font-awesome提供的图标
  icons: {
    text: 'fa fa-pencil',
    color: 'fa fa-paint-brush',
    font: 'fa fa-font',
    align: 'fa fa-align-justify',
    list: 'fa fa-list',
    link: 'fa fa-chain',
    unlink: 'fa fa-chain-broken',
    tabulation: 'fa fa-table',
    image: 'fa fa-file-image-o',
    hr: 'fa fa-minus',
    eraser: 'fa fa-eraser',
    undo: 'fa-undo fa',
    'full-screen': 'fa fa-arrows-alt',
    info: 'fa fa-info',
  },
  // 语言，内建的有英文（en-us）和中文（zh-cn）
  language: 'zh-cn',
  // 自定义语言
  i18n: {
    'zh-cn': {
      align: '对齐方式',
      image: '图片',
      list: '列表',
      link: '链接',
      unlink: '去除链接',
      table: '表格',
      font: '文字',
      'full screen': '全屏',
      text: '排版',
      eraser: '格式清除',
      info: '关于',
      color: '颜色',
      'please enter a url': '请输入地址',
      'create link': '创建链接',
      bold: '加粗',
      italic: '倾斜',
      underline: '下划线',
      'strike through': '删除线',
      subscript: '上标',
      superscript: '下标',
      heading: '标题',
      'font name': '字体',
      'font size': '文字大小',
      'left justify': '左对齐',
      'center justify': '居中',
      'right justify': '右对齐',
      'ordered list': '有序列表',
      'unordered list': '无序列表',
      'fore color': '前景色',
      'background color': '背景色',
      'row count': '行数',
      'column count': '列数',
      save: '确定',
      upload: '上传',
      progress: '进度',
      unknown: '未知',
      'please wait': '请稍等',
      error: '错误',
      abort: '中断',
      reset: '重置',
    },
  },
  // 隐藏不想要显示出来的模块
  hiddenModules: [],
  // 自定义要显示的模块，并控制顺序
  visibleModules: [],
  // 扩展模块，具体可以参考examples或查看源码
  // extended modules
  modules: {
    //omit,reference to source code of build-in modules
  },
})
export default new Vue({
  store,
  router,
  i18n,
  render: (h) => h(App),
}).$mount('#app')
