<template>
  <div class="van-hairline--bottom">
    <van-field
      :value="account"
      disabled
      center
      clearable
      :label="$t('account.account')"
      :placeholder="$t('account.not_logged_in')"
    >
      <template slot="button">
        <van-button v-if="isLogin" size="small" type="primary" @click="logout">
          {{ $t('account.log_out') }}
        </van-button>
        <van-button v-else size="small" type="primary" @click="login">
          {{ $t('account.log_in') }}
        </van-button>
      </template>
    </van-field>
  </div>
</template>

<script>
import { Toast } from 'vant'

export default {
  computed: {
    isLogin() {
      return this.$store.state.account.name
    },
    account() {
      return this.isLogin
        ? `${this.$store.state.account.name}@${this.$store.state.account.authority}`
        : this.$t('account.not_logged_in')
    },
  },
  methods: {
    logout() {
      this.$ScatterJS
        .logout()
        .then((res) => {
          if (res) {
            this.$store.commit('SET_ACCOUNT', {})
            Toast.success(this.$t('account.logged_out'))
          } else {
            Toast.fail(this.$t('account.error_logging_out'))
          }
        })
        .catch((err) => {
          Toast(err)
        })
    },
    login() {
      this.$ScatterJS
        .login()
        .then((id) => {
          if (id) {
            this.$store.commit('SET_ACCOUNT', id.accounts[0])
            
            Toast.success(this.$t('account.logged_in'))
          } else {
            Toast.fail(this.$t('account.error_logging_in'))
          }
        })
        .catch((err) => {
          Toast(`login error：${err}`)
        })
    },
  },
}
</script>
