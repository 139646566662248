// import store from '@/store'
import Vue from 'vue'
import Yta from 'ytajs-16'
import ScatterJS from 'scatterjs-yta-core'
import ScatterYTA from 'scatterjs-plugin-ytajs'
import config from '@/config'
import store from '@/store'
import { Toast } from 'vant'
import qs from 'qs'
import { isPc } from '@/utils/index'
import _this from '../main.js'

const query = qs.parse(location.href.split('?')[1])

console.log(query)

ScatterJS.plugins(new ScatterYTA())

const network = ScatterJS.Network.fromJson(config.network[query.env || 'main'])

function connect() {
  ScatterJS.connect('YottaChain', {
    network,
  })
    .then((connected) => {
      if (!connected) {
        console.log('连接失败')
        // Toast.fail('连接失败')
        document.addEventListener('scatterLoaded', () => {
          connect()
        })
      } else {
        console.log('连接成功')
        // Toast('连接成功')

        let yta = null

        if (window.android && window.android.hasOwnProperty('getAccount')) {
          yta = ScatterJS.yta(network, Yta)
        } else {
          yta = ScatterJS.eos(network, Yta)
        }
        Vue.prototype.$yta = yta
        console.log(yta)
        Vue.prototype.$ScatterJS = ScatterJS
        console.log(ScatterJS)
        store.commit('SET_WALLET', ScatterJS.scatter.wallet)

        let logined = false

        if (isPc()) {
          setTimeout(() => {
            if (!logined) Toast(_this.$t('tip.tip0'))
          }, 5000)
        }

        ScatterJS.login()
          .then((id) => {
            logined = true
            // console.log(id)
            console.log('登录成功')
            // Toast('登录成功')
            // id.accounts[0].name = 'tongweikeji3'
            store.commit('SET_ACCOUNT', id.accounts[0])
            store.dispatch('getUser',{
              account:id.accounts[0]['name']
            })
            store.dispatch('getBalance')
            store.dispatch('changeload')
          })
          .catch((err) => {
            // Toast(`login error：${err}`)
            console.log(err)
            Toast(`未检测到您的主网账号`)
          })
      }
    })
    .catch((err) => {
      Toast(`connect error：${err}`)
    })
}

connect()
