import Vue from 'vue'
export const vue = new Vue()
import { Toast } from 'vant'

export function pushTransaction(actions) {
  console.log(actions)
  console.log(1123)
  return new Promise(async (resolve, reject) => {
    try {
      const result = await vue.$yta.transaction(
        {
          actions,
        },
        {
          blocksBehind: 3,
          expireSeconds: 30,
        }
      )
      resolve(result)
    } catch (err) {
      console.error(`Error：`, err)
      try {
        Toast(`Error：${JSON.parse(err).error.details[0].message}`)
      } catch (err1) {
        Toast(`Error：${err.message}`)
      }
      // reject(err)
      console.log(reject)
    }
  })
}

// 查表
export function getTableRows(data) {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await vue.$yta.getTableRows(data)
      resolve(result)
    } catch (err) {
      console.error(err)
      try {
        Toast(`Error：${JSON.parse(err).error.details[0].message}`)
      } catch (err1) {
        Toast(`Error：${err.message}`)
      }
      reject(err)
    }
  })
}
