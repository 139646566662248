import request from '@/utils/request'

//上传图片
export function upload(data) {
  return request({
    url: '/service/Upload/upload',
    method: 'post',
    data,
  })
}

//报名接口
export function toSignUp(data) {
  return request({
    url: '/committee/committee/committeeSignUp',
    method: 'post',
    data,
  })
}

// 投票首页列表接口
export function getList(data) {
  return request({
    url: '/committee/committee/getList',
    method: 'post',
    data,
  })
}
// 详情页面
// 投票首页列表接口
export function getUserData(data) {
  return request({
    url: '/committee/committee/getUserData',
    method: 'post',
    data,
  })
}

// 投票详情的列表接口
// 投票详情列表接口
export function getVoteRecordDataByAccount(data) {
  return request({
    url: '/committee/committee/getVoteRecordDataByAccount',
    method: 'post',
    data,
  })
}

// 不可投票地址
export function getNoVoteAccountList(data) {
  return request({
    url: '/committee/committee/getNoVoteAccountList',
    method: 'post',
    data,
  })
}

// 首页用户统计项
export function getConfig(data) {
  return request({
    url: '/committee/committee/getConfig',
    method: 'post',
    data,
  })
}

// 用户初始信息获取
export function getInitData(data) {
  return request({
    url: '/committee/committee/getInitData',
    method: 'post',
    data,
  })
}

// 投票接口
export function toUserVote(data) {
  return request({
    url: '/committee/committee/UserVote',
    method: 'post',
    data,
  })
}

//第二届新增--判断地址是不是超节点
export function isSuperNode(data) {
  return request({
    url: '/committee/committee/isSuperNode',
    method: 'post',
    data,
  })
}
