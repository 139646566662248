<template>
  <div id="app">
    <Account v-if="!isApp" />
    <!-- 账号未注册提示 -->
    <!-- <div class="tips">
      <div style="padding-bottom: 6px;">
        {{ $t('tip.account_name') }}：{{ account }}
      </div>
      <div>
        {{ $t('tip.tiptxt') }}
      </div>
    </div> -->
    <!-- 路由 -->
    <router-view
      v-wechat-title="$route.meta.title"
      v-if="isRouterAlive && $store.state.isReload"
    />
    <!-- 微信提示 -->

    <!-- <div v-else-if="!isBitKeep && !account">
      <WechatTip />
    </div> -->
    <!-- 登录中提示 -->
    <!-- <div class="loading">
      <van-loading size="24px">
        {{ $t('tip.logging_in') }}
      </van-loading>
    </div> -->
  </div>
</template>

<script>
import Account from '@/components/Account'
// import WechatTip from '@/components/wechatTip'
import { isBitKeep } from '@/utils'

export default {
  provide() {
    //父组件中通过provide来提供变量，在子组件中通过inject来注入变量。
    return {
      reload: this.reload,
    }
  },
  components: {
    Account,
    // WechatTip,
  },
  data() {
    return {
      isRouterAlive: true, //控制视图是否显示的变量
    }
  },
  computed: {
    isBitKeep() {
      return isBitKeep()
    },
  },
  methods: {
    reload() {
      this.isRouterAlive = false //先关闭，
      this.$nextTick(function() {
        this.isRouterAlive = true //再打开
      })
    },
  },
}
</script>
<style>
input::-webkit-input-placeholder {
  /*WebKit browsers*/
  color: #929399;
}
input::-moz-input-placeholder {
  /*Mozilla Firefox*/
  color: #929399;
}
input::-ms-input-placeholder {
  /*Internet Explorer*/
  color: #929399;
}
</style>
<style lang="less" scoped>
#app {
  min-height: 100vh;
  background: #eef2f6;
  .loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  .tips {
    padding: 20px;
    line-height: 18px;
  }
  .kefu-btn {
    position: fixed;
    right: 20px;
    bottom: 20px;
  }
  .kefu-warpper {
    border-radius: 6px;
  }
  .kefu-qrcode {
    width: 80vw;
    border-radius: 6px;
  }
}
</style>
