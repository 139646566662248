export default {
  index: {
    // balance:'余额',
    // deposit_withdraw:'存取YTA',
    // circulation:'MTA流通量',
    // exchange_rate :'兑换比例',
    // set_up:'去设置',
    // amount:'数量',
    // please_enter_the_amount:'请输入数量',
    // available:'可获得',
    // confirm_the_exchange:'确认兑换',
    // error_getting_available_balance:'获取可用余额错误',
    // error_getting_MTA_balance:'获取MTA余额错误',
    // error_getting_set_scale:'获取设置比例错误',
    // error_getting_MTA_in_circulation:'获取MTA流通量错误',
    // exchange_successful:'兑换成功',
    confirm: '确认',
    cancel: '取消',
    Proposal_list: '提案列表',
    temporarily_no_data: '暂无数据',
    no_more: '没有更多了',
    start_time: '开始时间',
    end_time: '结束时间',
    proposal_approved: '提案通过',
    proposal_not_approved: '提案未通过',
    voting_did_not_start: '投票未开始',
    voting_ended: '投票结束于',
    day_ago: '天前',
    voting_underway: '投票进行中',
    total_votes: '合计票数',
    agree: '赞同',
    disagree: '反对',
    abstain: '弃权',
    all: '全部',
    please_select_voting_status: '请选择投票状态',
    basic_proposal_information: '提案基本信息',
    background: '简介',
    impact: '影响',
    purpose: '目的',
    criteria_for_proposal_approval: '提案通过标准',
    affirmative_votes: '参与投票量大于总可投票量的',
    over_msg: '%',
    affirmative_votes_two: '赞同票大于反对票，且溢出总可投票量的',
    over_msg_two: '%',
    proposal_attachment: '提案附件',
    initiator: '发起人',
    details_of_the_proposal: '提案详情',
    voting_information: '投票相关信息',
    status: '状态',
    // proposal_not_approved:'提案未通过',
    // proposal_approved:'提案通过',
    type: '类型',
    token_holders_vote: '代币持有者投票',
    total_number_of_votes_available: '可投票总票数',
    non_participating_address: '不可参与地址',
    view: '去查看',
    voting_hasnt_started: '投票未开始',
    vote: '投票',
    voted: '已投',
    yes_vote: '赞同票',
    no_vote: '反对票',
    abstention_vote: '弃权票',
    change_the_vote: '改投',
    you_have_cast_vote: '您已投',
    voteInfo: '票',
    last_updated_time: '最后更新时间',
    voting_detail: '投票详情',
    voting_no_started: '投票未开始',
    voting: '投票中',
    voting_address: '投票地址',
    number_of_voted: '投票数',
    voting_status: '投票状态',
    no_found: '未获取到',
    users_cannots_vote: '用户不可投票',
    no_moment_vote: '当前时间不可投票',
    vote_wallet: '未检测到主网账户，如需投票请配合钱包一起操作',
    vote_over_today: '投票结束于今天',
    voting_over: '投票已结束',
    account_not_found: '未检测到主网账户',
  },
  deposit: {
    please_enter_the_deposit_amount: '请输入存入数量',
    please_enter_the_withdrawal_amount: '请输入取出数量',
    deposit: '存入',
    withdraw: '取出',
    deposited_successfully: '存入成功',
    withdrawal_Succussed: '取出成功',
  },
  setting: {
    please_set_the_exchange_rate: '请设置兑换比例',
    please_enter_the_exchange_rate: '请输入兑换比例',
    setup_successful: '设置成功',
    exchange: '兑换',
  },
  account: {
    account: '账户',
    not_logged_in: '未登录',
    log_out: '退出',
    log_in: '登录',
    logged_out: '退出成功',
    error_logging_out: '退出失败',
    logged_in: '登录成功',
    error_logging_in: '登录失败',
  },
  tip: {
    // 提示、404、其他页面
    page_not_found: '页面未找到',
    back_to_home_page: '回到首页',
    gentle_Reminder: '温馨提示',
    your_main_account_not_found: '未检测到您的主网账号',
    please_wallet: '请保证自己在bitkeep钱包扫描此二维码',
    bitkeep_download_link: 'bitkeep下载链接',
    tip0: '请清除浏览器插件权限后重新登录！',
    loading: '加载中...',
    account_name: '账户名',
    logging_in: '登录中',
    tiptxt:
      'YTA的账户名是由a-z、1-5组成的长度位12位的字符串，当前账户名不正确，请确认当前私钥对应的YTA账户已经在YottaChain主网注册，如果已经注册请重新导入私钥后再试。',
  },
  enroll: {
    Governance_Committee: '治理委员会',
    Number_of_Applicants: '报名人数',
    Total_Votes: '总票数',
    Day: '天',
    Days: '天',
    Hour: '时',
    Hours: '时',
    Minute: '分',
    Minutes: '分',
    left_until_the_voting_ends: '投票结束',
    no_start_until: '投票开始',
    Non_participating_address: '不可参与地址',
    I_want_to_register: '我要报名',
    No_Votes: '暂无投票',
    Homepage: '首页',
    You_have_submitted: '您已投给',
    approval_vote_for: '赞同票给 ',
    disapproval_vote_for: '反对票给 ',
    Please_search_for_a_name: '请搜索名字',
    Search: '搜索',
    My_Registration: '我的报名',
    More: '更多',
    Approve: '赞同',
    Disapprove: '反对',
    Voting_Address: '投票地址',
    Voting_Status: '投票状态',
    Number_of_Votes: '投票数',
    Cancel: '取消',
    Name: '名字',
    The_length_should_not_exceed_10_characters: '长度不可超过10个字符',
    Inroduction: '竞选声明',
    The_length_should_not_exceed_800_characters: '长度不可超过800个字符',
    Logo_Image: '标识图片',
    image_size: '图片尺寸为(220x220)，大小不能超过5M',
    The_number_of_YTA_in_stake: '抵押YTA数量:',
    The_registration_time_is: '此次报名时间为:',
    Register: '报名',
    Image_size_limit_exceeded: '文件超过限制大小',
    Please_enter_your_name: '请输入名字 ',
    Please_fill_in_the_introduction: '请填写竞选声明',
    Please_upload_the_logo_image: '请上传标识图片',
    Successful_Registration: '报名成功',
    Registration_Failed: '报名失败',
    Upload_Successful: '上传成功 !',
    file_failed: '文件上传失败,请重试!',
    vote_successfully: '投票成功',
    vote_failed: '投票失败,请重试',
    voating: '投票中',
    Vote_Cancelled: '取消投票成功',
    Read_Less: '收起',
    balance_is_less: '您的余额不足1000000YTA',
    Registration_has_not_started: '报名未开始',
    isnopic: '如无法上传图片，请选择默认图片按钮 ',
    usePic: '使用默认标识图片',
    Staking_Address: '抵押地址:',
  },
  second: {
    Initiate_Proposal: '发起提案',
    Please_select_proposal_type: '请选择提案类型',
    All_Types: '全部类型',
    Token_Holders_Vote: '代币持有者投票',
    Governance_Committee_Vote: '治理委员会投票',
    Proposal_Name: '提案名称',
    Please_enter_the_proposal_name: '请输入提案名称',
    Proposal_Introduction: '提案简介',
    Please_enter_the_proposal_introduction: '请输入提案简介',
    Proposal_Details: '提案详情',
    Please_enter_the_proposal_details: '请输入提案详情',
    Proposal_File: '提案文件',
    File_Uploaded_Successfully: '文件上传成功',
    File_upload_failed: '文件上传失败,请重试!',
    The_proposal_will_be_closed_in_X_days: '提案将于三天后结束',
    Cancel: '撤销',
    Support_The_Proposal: '附议',
    Supported_By: '附议人',
    Cancelled: '撤销成功',
    Supported_Successfully: '附议成功',
    The_operation_failed: '操作失败',
    Supporting: '附议中',
    Proposal_Supported: '已附议',
    More_than: '超过',
    of_the_governance: '的治理委员会委员投票',
    Positive_votes_exceed_of_the_total_votes: '赞成票超过投票总数的50%',
    Governance_Committee_Members_Vote: '治理委员会委员投票',
    Successfully_Initiated: '发起成功',
    Failed_to_initiate: '发起失败',
    Please_upload_the_file_in_format: '请上传PDF格式的文件',
    Voter_Name: '投票人姓名',
  },
}
