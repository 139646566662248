import Vue from 'vue'
import 'vant/lib/index.less'
import {
  Button,
  Cell,
  CellGroup,
  Field,
  Search,
  List,
  Progress,
  Divider,
  Tab,
  Tabs,
  Tag,
  Grid,
  GridItem,
  Switch,
  Overlay,
  Loading,
  Collapse,
  CollapseItem,
  Sticky,
  Popup,
  Tabbar,
  TabbarItem,
  Icon,
  Checkbox,
  CheckboxGroup,
  Slider,
  Form,
  Image,
  RadioGroup, 
  Radio,
  Picker
} from 'vant'

Vue.use(Button)
  .use(Cell)
  .use(CellGroup)
  .use(Field)
  .use(Search)
  .use(List)
  .use(Progress)
  .use(Divider)
  .use(Tab)
  .use(Tabs)
  .use(Tag)
  .use(Grid)
  .use(GridItem)
  .use(Switch)
  .use(Overlay)
  .use(Loading)
  .use(Collapse)
  .use(CollapseItem)
  .use(Sticky)
  .use(Popup)
  .use(Tabbar)
  .use(TabbarItem)
  .use(Icon)
  .use(Checkbox)
  .use(CheckboxGroup)
  .use(Slider)
  .use(Form)
  .use(Image)
  .use(RadioGroup)
  .use(Radio)
  .use(Picker)
  