<template>
  <span>{{ value / 10000 | toThousands }} YTA</span>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      default: 0
    }
  }
};
</script>