import { getSignData, userVote } from '@/api/index'
import { toSignUp, toUserVote } from '@/api/enroll'
import store from '@/store'
// import cookie from 'js-cookie'
export function objKeySort(arys) {
  //先用Object内置类的keys方法获取要排序对象的属性名数组，再利用Array的sort方法进行排序
  var newkey = Object.keys(arys).sort()
  var newObj = '' //创建一个新的对象，用于存放排好序的键值对
  for (var i = 0; i < newkey.length; i++) {
    //遍历newkey数组
    newObj += [newkey[i]] + '=' + arys[newkey[i]] + '&'
  }
  return newObj.substring(0, newObj.length - 1)
}

export function isPc() {
  return !navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  )
}

export function isIos() {
  return navigator.userAgent.match(/(iPhone|iPod|ios|iPad|)/i)
}

export function isAndroid() {
  return navigator.userAgent.match(/(Android)/i)
}

export function isBitKeep() {
  return navigator.userAgent.match(/(BitKeep)/i)
}

// 格式化文件单位
export function formatFileSize(bytes) {
  if (isNaN(bytes)) {
    return ''
  }
  var symbols = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  var exp = Math.floor(Math.log(bytes) / Math.log(2))
  if (exp < 1) {
    exp = 0
  }
  var i = Math.floor(exp / 10)
  bytes = bytes / Math.pow(2, 10 * i)

  if (bytes.toString().length > bytes.toFixed(2).toString().length) {
    bytes = bytes.toFixed(2)
  }
  return {
    size: bytes,
    unit: symbols[i],
    string: `${bytes} ${symbols[i]}`,
  }
}

// 将文件大小格式化成指定单位
export function unifiedFileSize(bytes, unit) {
  if (isNaN(bytes)) {
    return ''
  }
  var symbols = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  const i = symbols.indexOf(unit)
  bytes = bytes / Math.pow(2, 10 * i)
  return {
    size: bytes,
    unit: unit,
  }
}
// 时间戳和时间格式的相互转换
export function timeType(time, num) {
  time = Number(time + '000')
  let EndTime = new Date(time)
  let year = EndTime.getFullYear()

  let month = EndTime.getMonth() + 1
  if (month < 10) {
    month = '0' + month
  }

  let date = EndTime.getDate()
  if (date < 10) {
    date = '0' + date
  }

  let hours = EndTime.getHours()
  if (hours < 10) {
    hours = '0' + hours
  }
  let minutes = EndTime.getMinutes()
  if (minutes < 10) {
    minutes = '0' + minutes
  }
  let seconds = EndTime.getSeconds()
  if (seconds < 10) {
    seconds = '0' + seconds
  }
  if (num == 1) {
    return year + '-' + month + '-' + date
  } else if (num == 2) {
    return hours + ':' + minutes + ':' + seconds
  }
}

// 用户投票
export async function gotoVote(id, type) {
  let res = await getSignData()
  let obj = {}
  obj.signature =
    res.data.timestamp.toString() +
    res.data.id.toString() +
    res.data.random.toString()
  obj.account = store.state.account.name
  obj.random = res.data.random.toString()
  obj.id = id
  obj.vote_type = type

  let result = await userVote(obj)
  return result
}
// 用户投票
export async function signUp(
  name,
  introduction,
  img_url,
  mortgage_number,
  trx_id
) {
  let res = await getSignData()
  let obj = {}
  obj.name = name
  obj.introduction = introduction
  obj.img_url = img_url
  obj.mortgage_number = '1000000'
  if (trx_id != '已经转过') {
    obj.trx_id = trx_id
  }
  obj.signature =
    res.data.timestamp.toString() +
    res.data.id.toString() +
    res.data.random.toString()
  obj.account = store.state.account.name
  obj.random = res.data.random.toString()
  let result = await toSignUp(obj)
  return result
}

// 新的用户投票
export async function isUserVote(committee_user_id, type) {
  let obj = await getSignObj()
  obj.committee_user_id = committee_user_id
  obj.type = type
  let result = await toUserVote(obj)
  return result
}

//获取签名对象signData
export async function getSignObj() {
  let res = await getSignData()
  let obj = {}
  obj.signature =
    res.data.timestamp.toString() +
    res.data.id.toString() +
    res.data.random.toString()
  obj.account = store.state.account.name
  obj.random = res.data.random.toString()

  return obj
}
