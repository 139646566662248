import request from '@/utils/request'
import {
  getTableRows,
} from '@/utils/pushTransaction'

// 获取MTA信息 流通量发行量
export async function getMtaInfo() {
  const data = {
    code: 'token.mta',
    table: 'stat',
    scope: 4281421,
    json: true,
    limit: 1,
  }
  return await getTableRows(data)
}

// 获取记录
export function getRecord(params) {
  return request({
    url: '/getRecord',
    method: 'get',
    params,
  })
}

export function getProposalList(data) {
  return request({
    url: '/proposal/proposal/getProposalList',
    method: 'post',
    data,
  })
}

// 验签获取数据程序
export function getSignData(data) {
  return request({
    url: '/user/RegisterLogin/getSignData',
    method: 'post',
    data,
  })
}

// 初始化调用 
export function login(data) {
  return request({
    url: '/user/RegisterLogin/login',
    method: 'post',
    data,
  })
}

//用户投票
export function userVote(data) {
  return request({
    url: '/vote/vote/userVote',
    method: 'post',
    data,
  })
}