export default {
  network: {
    // 主网
    main: {
      blockchain: 'yta',
      chainId: '9d7bec4bf167a7b136d0b45d8aac77bd45e761e35cbd2b7d0e88dfe05ebf3d62',
      host: 'wallet.yottachain.net',
      port: 443,
      protocol: 'https',
    },

    // 研发网
    dev: {
      blockchain: 'yta',
      chainId: '0e3be5e21a0ed318c7a07a6481aa953205ea21567253bffb43a573fba5f171bd',
      host: '47.112.119.28',
      port: 8888,
      protocol: 'http',
    },

    // 测试网
    test: {
      blockchain: 'yta',
      chainId: '80761476d133f04e6fbd9b85d8130cd9b3c673f38b7e9ca91b73164b36c43b04',
      host: '132.232.219.237',
      port: 8181,
      protocol: 'http',
    },
  },
}
