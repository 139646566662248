export default {
  index: {
    // balance:' Balance',
    // deposit_withdraw:'Deposit-withdraw YTA',
    // circulation:'MTA in circulation',
    // exchange_rate :'Exchange Rate',
    // set_up:'Set up',
    // amount:'Amount',
    // please_enter_the_amount:'Please enter the amount',
    // available:'Available ',
    // confirm_the_exchange:'Confirm the exchange',
    // error_getting_available_balance:'Error getting available balance',
    // error_getting_MTA_balance:'Error getting MTA balance',
    // error_getting_set_scale:'Error getting set scale',
    // error_getting_MTA_in_circulation:'Error getting MTA in circulation',
    // exchange_successful:'Exchange successful',
    confirm: 'Confirm',
    cancel: 'Cancel',
    Proposal_list: 'Proposal List',
    temporarily_no_data: 'Temporarily no data',
    no_more: 'No More',
    start_time: 'Start time',
    end_time: 'End time',
    proposal_approved: 'proposal approved',
    proposal_not_approved: 'proposal not approved ',
    voting_did_not_start: 'Voting did not start',
    voting_ended: 'Voting ended ',
    day_ago: ' days ago',
    voting_underway: 'Voting underway',
    total_votes: 'Total votes',
    agree: 'Agree',
    disagree: 'Disagree',
    abstain: 'Abstain',
    all: 'All',
    please_select_voting_status: 'Please select voting status',
    basic_proposal_information: 'Basic Proposal Information',
    background: 'Brief Introduction',
    impact: 'Impact',
    purpose: 'Purpose',
    criteria_for_proposal_approval: 'Criteria for Proposal Approval',
    affirmative_votes: '参与投票量大于总可投票量的',
    over_msg: '%',
    affirmative_votes_two: '同时赞同票大于反对票，且溢出总投票量的',
    over_msg_two: '%',
    proposal_attachment: 'Proposal Attachment',
    initiator: 'Proposed by',
    details_of_the_proposal: 'Details of the proposal',
    voting_information: 'Voting Information',
    status: 'Status',
    // proposal_not_approved:'proposal not approved ',
    // proposal_approved:'proposal approved',
    type: 'Type',
    token_holders_vote: 'Token holders vote',
    total_number_of_votes_available: 'Total number of votes available',
    non_participating_address: 'Non-Participating Addresses',
    view: 'View',
    voting_hasnt_started: "Voting hasn't started",
    vote: 'Vote',
    voted: 'Voted',
    yes_vote: 'Yes vote/Affirmative vote',
    no_vote: 'No vote/negative vote',
    abstention_vote: 'Abstention vote',
    change_the_vote: 'Change the vote',
    you_have_cast_vote: 'You have cast',
    voteInfo: 'vote',
    last_updated_time: 'Last Updated Time',
    voting_detail: 'Voting details',
    voting_no_started: "Voting hasn't started",
    voting: 'Voting',
    voting_address: 'Voting Address',
    number_of_voted: 'Number of Votes',
    voting_status: 'Voting Status',
    no_found: 'Not found',
    users_cannots_vote: 'Users cannot vote',
    no_moment_vote: 'Voting is not available at the moment',
    vote_wallet:
      'The mainnet account has not been found, If you need to vote, please cooperate with your wallet',
    vote_over_today: 'Voting ended in today',
    voting_over: 'Voting is over',
    account_not_found: 'The mainnet account has not been found',
  },
  deposit: {
    please_enter_the_deposit_amount: 'Please enter the deposit amount',
    please_enter_the_withdrawal_amount: 'Please enter the withdrawal amount',
    deposit: 'Deposit',
    withdraw: 'Withdraw',
    deposited_successfully: 'Deposited successfully',
    withdrawal_Succussed: 'Withdrawal Succussed',
  },
  setting: {
    please_set_the_exchange_rate: 'Please set the exchange rate',
    please_enter_the_exchange_rate: 'Please enter the exchange rate',
    setup_successful: 'setup successful',
    exchange: 'Exchange',
  },
  account: {
    account: 'Account',
    not_logged_in: 'Not logged in',
    log_out: 'log out',
    log_in: 'log in',
    logged_out: 'logged out',
    error_logging_out: 'Error logging out',
    logged_in: 'logged in',
    error_logging_in: 'Error logging in',
  },
  tip: {
    // 提示、404、其他页面
    page_not_found: 'Page not found',
    back_to_home_page: 'Back to home page',
    gentle_Reminder: 'Gentle Reminder',
    your_main_account_not_found: 'Your main account not found',
    please_wallet: 'Please make sure you scan this QR code in Bitkeep wallet',
    bitkeep_download_link: 'Bitkeep download link',
    tip0: 'Please clear the browser plug-in permissions and log in again!',
    loading: 'Loading...',
    account_name: 'Account Name',
    logging_in: 'Logging in...',
    tiptxt:
      'The account name of YTA is a 12-bit string composed of A-Z and 1-5. The current account name is not correct. Please confirm the YTA account corresponding to the current private key has been registered in Yottachain mainnet.if registered, please import the private key and try again.',
  },
  enroll: {
    Governance_Committee: 'Governance Committee',
    Number_of_Applicants: 'Number of Applicants',
    Total_Votes: 'Total Votes',
    Day: ' Day ',
    Days: ' Days ',
    Hour: ' Hour ',
    Hours: ' Hours ',
    Minute: ' Minute ',
    Minutes: ' Minutes ',
    left_until_the_voting_ends: 'left until the voting ends',
    no_start_until: 'until the voting open',
    Non_participating_address: 'Non-participating address',
    I_want_to_register: 'I want to register',
    No_Votes: 'No Votes',
    Homepage: 'Homepage',
    You_have_submitted: 'You have submitted',
    approval_vote_for: 'approval vote for ',
    disapproval_vote_for: 'disapproval vote for ',
    Please_search_for_a_name: 'Please search for a name',
    Search: 'Search',
    My_Registration: 'My Registration',
    More: 'More',
    Approve: 'Approve',
    Disapprove: 'Disapprove',
    Voting_Address: 'Voting Address',
    Voting_Status: 'Voting Status',
    Number_of_Votes: 'Number of Votes',
    Cancel: 'Cancel',
    Name: 'Name',
    The_length_should_not_exceed_10_characters:
      'The length should not exceed 10 characters',
    Inroduction: 'Campaign Statement',
    The_length_should_not_exceed_800_characters:
      'The length should not exceed 800 characters',
    Logo_Image: 'Logo Image',
    image_size:
      'The image size should be (220x220), and the size cannot exceed 5M',
    The_number_of_YTA_in_stake: 'The number of YTA in stake:',
    The_registration_time_is: 'The registration time is',
    Register: 'Register',
    Image_size_limit_exceeded: 'The file size exceeds the limit ',
    Please_enter_your_name: 'Please enter your name',
    Please_fill_in_the_introduction: 'Please fill in the campaign statement',
    Please_upload_the_logo_image: 'Please upload the logo image',
    Successful_Registration: 'Successful Registration',
    Registration_Failed: 'Registration Failed',
    Upload_Successful: 'Upload Successful !',
    file_failed: 'File upload failed. Please try again!',
    vote_successfully: 'Your vote has been successfully submitted',
    vote_failed: 'Vote failed, Please try again!',
    voating: 'Voting',
    Vote_Cancelled: 'Vote Cancelled',
    Read_Less: 'Read Less',
    balance_is_less: 'Your balance is less than 1000000YTA',
    Registration_has_not_started: 'Registration has not started',
    isnopic: 'If the image cannot be uploaded, select the default image button',
    usePic: 'Use the default  image',
    Staking_Address: 'Staking Address:',
  },
  second: {
    Initiate_Proposal: 'Initiate Proposal',
    Please_select_proposal_type: 'Please select proposal type',
    All_Types: 'All Types',
    Token_Holders_Vote: 'Token Holders Vote',
    Governance_Committee_Vote: 'Governance Committee Vote',
    Proposal_Name: 'Proposal Name',
    Please_enter_the_proposal_name: 'Please enter the proposal name',
    Proposal_Introduction: 'Proposal Introduction',
    Please_enter_the_proposal_introduction:
      'Please enter the proposal introduction',
    Proposal_Details: 'Proposal Details',
    Please_enter_the_proposal_details: 'Please enter the proposal details',
    Proposal_File: 'Proposal File',
    File_Uploaded_Successfully: 'File Uploaded Successfully',
    File_upload_failed: 'File upload failed. Please try again!',
    The_proposal_will_be_closed_in_X_days:
      'The proposal will be closed in 3 days',
    Cancel: 'Cancel',
    Support_The_Proposal: 'Support The Proposal',
    Supported_By: 'Supported By',
    Cancelled: 'Cancelled',
    Supported_Successfully: 'Supported Successfully',
    The_operation_failed: 'The operation failed',
    Supporting: 'Supporting',
    Proposal_Supported: 'Proposal Supported',
    More_than: 'More than',
    of_the_governance: 'of the governance committee members voted',
    Positive_votes_exceed_of_the_total_votes:
      'Positive votes exceed 50% of the total votes',
    Governance_Committee_Members_Vote: 'Governance Committee Members Vote',
    Successfully_Initiated: 'Successfully Initiated',
    Failed_to_initiate: 'Failed to initiate',
    Please_upload_the_file_in_format: 'Please upload the file in PDF format',
    Voter_Name: 'Voter Name',
  },
}
